<template>
  <div class="bgc">
    <div class="login-page">
      <div class="logo">
        <div style="height: 30px"></div>
      </div>
      <h4 class="title">WMS/WCS移动端</h4>
      <van-form ref="loginForm" @submit="onSubmit">
        <div class="loginForm">
          <van-field v-model="loginData.username" name="账号" label="账号" placeholder="账号" :rules="[{ required: true, message: '账号' }]" ref="username" />
          <van-field v-model="loginData.password" type="password" name="密码" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" ref="password" />
        </div>
        <div style="width: 70%;margin:10px auto">
          <van-radio-group icon-size="16px" v-model="checked" direction="horizontal">
            <van-radio name="1">斑马</van-radio>
            <van-radio name="2">霍尼韦尔</van-radio>
          </van-radio-group>
        </div>
        <div style="width: 70%;margin:10px auto">
          <van-button round block type="primary" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loginData: {
          username: '',
          password: ''
        },
        checked: null
      }
    },
    watch: {
      $route: {
        handler: function(route) {
          const query = route.query
          if (query) {
            this.redirect = query.redirect
            this.otherQuery = this.getOtherQuery(query)
          }
        },
        immediate: true
      }
    },
    mounted() {
      if (this.loginData.username === '') {
        this.$refs.username.focus()
      } else if (this.loginData.password === '') {
        this.$refs.password.focus()
      }

      const storageUsername = localStorage.getItem('username')
      const storagePassword = localStorage.getItem('password')
      if (storageUsername && storagePassword) {
        this.loginData.username = storageUsername
        this.loginData.password = storagePassword
      }
    },
    methods: {
      onSubmit() {
        this.$store.dispatch('user/login', this.loginData).then(() => {
          this.$router.push({ path: '/index', query: this.otherQuery })
          this.loading = false
        })
      },
      getOtherQuery(query) {
        return Object.keys(query).reduce((acc, cur) => {
          if (cur !== 'redirect') {
            acc[cur] = query[cur]
          }
          return acc
        }, {})
      }
    }
  }
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.bgc{
  height: 100vh;
  background: url('../../assets/login-bg-m.png') no-repeat fixed center;
  overflow: hidden;
}
.login-page{
  width: 80%;
  height: 300px;
  background-color: #fff;
  margin: 200px auto;

}
.title{
  color: white;
  font-size: 14px;
  text-shadow: -1px 1px 0 #999, 1px 1px 0 #999, 1px -1px 0 #999,
  -1px -1px 0 #999;
  margin-bottom: 10px;
}
.logo{
  display: flex;
  justify-content: center;
  background-color: #fff;
}
h4{
  background-color: #fff;
  text-align: center;
  font-size: 18px;
}
.logo-img{
  width:160px;
  margin: 20px auto 8px;
}
.field{
  display: none !important;
}
.loginForm{
  width: 100%;
  margin-right: 20px;
}
</style>
